// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-templates-index-page-jsx": () => import("./../../../src/templates/index-page.jsx" /* webpackChunkName: "component---src-templates-index-page-jsx" */),
  "component---src-templates-kontakt-page-jsx": () => import("./../../../src/templates/kontakt-page.jsx" /* webpackChunkName: "component---src-templates-kontakt-page-jsx" */),
  "component---src-templates-o-nas-page-jsx": () => import("./../../../src/templates/o-nas-page.jsx" /* webpackChunkName: "component---src-templates-o-nas-page-jsx" */),
  "component---src-templates-oferta-page-jsx": () => import("./../../../src/templates/oferta-page.jsx" /* webpackChunkName: "component---src-templates-oferta-page-jsx" */),
  "component---src-templates-portfolio-page-jsx": () => import("./../../../src/templates/portfolio-page.jsx" /* webpackChunkName: "component---src-templates-portfolio-page-jsx" */),
  "component---src-templates-portfolio-project-page-jsx": () => import("./../../../src/templates/portfolio-project-page.jsx" /* webpackChunkName: "component---src-templates-portfolio-project-page-jsx" */)
}

